.pg-cntr{
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.pg-cntr-no-header{
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -webkit-box-flex-direction: center;
	-webkit-flex-flex-direction: center;
	-ms-flex-flex-direction: center;
	-webkit-flex-direction: center;
  flex-direction: column;
  -webkit-box-align: center;
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
  align-items: center;
  padding: 0rem 1rem !important;
  margin-top: 42px;
}
.location-change{font-size: 0.8rem;}
.location-title{font-size: 1rem; line-height: 1.8; border-bottom: 1px dashed #b3b3b3; padding: 0.5rem; border-radius: 4px; font-weight: bold; margin: 0.5rem 0rem 0.5rem;}
.usercomment-root{z-index: 2000 !important}
.comment-cntr{width: 100%; border-radius: 4px; border: 1px solid #bcbcbc; 
  padding: 1rem; color: #666; font-size: 0.85rem; margin: 0.8rem 0rem;}
.usercomment-root{z-index: 2000}
.new-signal-trigger{padding: 1rem; border: 1px dashed #3f51b5; border-radius: 4px; 
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  -webkit-box-align: center;
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
  align-items: center;
  -ms-flex-justify-content: space-around;
	-webkit-justify-content: space-around;
  justify-content: space-around; 
  color: #3f51b5} 
.container-cntr{margin: 1rem;}
.vehicle-events-cntr{width: 100%; 
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  -webkit-box-flex-direction: center;
	-webkit-flex-flex-direction: center;
	-ms-flex-flex-direction: center;
	-webkit-flex-direction: center;
  flex-direction: column;
  margin-top: 1rem;
}
form{margin-top: 4rem;}
.text-field-cntr{padding: 0rem 1rem; background-color: #ffffff; border-radius: 8px;}
.btn-cntr{ display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  -ms-flex-justify-content: center;
	-webkit-justify-content: center;
  justify-content: center; 
  margin: 1rem 0rem;}
.err-msg{ height: 2.5rem; font-size: 0.85em; display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  color: #bc2222; 
  -ms-flex-justify-content: center;
	-webkit-justify-content: center;
  justify-content: center; 
  line-height: 2.5rem;}
.event-category-cntr { border-top: 1px solid #e7e7e7; margin-top: 1rem; padding-top: 0.35rem;}
.nm-category-cntr{border-top: 1px solid #e7e7e7; margin-top: 1rem; padding-top: 0.35rem; 
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  width: 100%; 
  -ms-flex-justify-content: space-around;
	-webkit-justify-content: space-around;
  justify-content: space-between;
  -webkit-box-align: center;
	-webkit-flex-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
  align-items: center;
}
.nm-category-title{flex-grow: 1;}
.nm-category-icon{margin-right: 0.85rem; height: 72px; width: 72px;}
.event-category-title { font-weight: 700; color: #3f51b5; }
.events{flex-grow: 1}
.event-cntr{
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  align-items: center; 
  flex-grow: 1
}
.event-blk{
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  padding: 0.5rem 0rem 0.5rem; align-items: center; flex-grow: 1;
  border-top: 1px solid #bcbcbc;
}
.event-text{flex-grow: 1; justify-content: space-between; font-size: 0.975rem;}
.category-icon{margin-right: 0.85rem; max-height: 120px; width: 120px;}
.header-bar{height: 42px; 
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; flex-grow: 1; background-color: #fff; 
  align-items: center; border-bottom: 1px solid #999;}
.signal-card{margin: 0rem 1rem 1rem;}
.signal-img{display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;}
.signal-card-content{
  font-size: 0.875rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
.signal-text {
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  align-items: flex-end;
  padding: 0.5rem;
  font-size: 1.125rem;
  position: absolute;
  transform: translate(0%,0%);
  bottom: 0px;
  background-color: rgba(255,255,255,0.8);
}
.sub-action{font-size: 0.8rem; margin-left: 2rem;}
.camera-action{
  color: #FFF;
  position: absolute;
  bottom: 10px;
  border: 1px solid;
  padding: 6px;
  border-radius: 24px;
  font-size: 0.85rem;
}
.camera-cntr{
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  -ms-flex-justify-content: center;
	-webkit-justify-content: center;
  justify-content: center;
  position: relative;
  background-color: #666666;
}
.action-btn-cntr{
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex; 
  -ms-flex-justify-content: center;
	-webkit-justify-content: center;
  justify-content: center;
}
.action-btn{border: 1px solid #0F2D4D; border-radius: 4px; line-height: 36px; padding: 0px 2rem; color:#000 !important}
.signal-description{text-align: center; margin: 1rem 0rem;}
.location-menu{color: #3f51b5; font-weight: bold;}
.location-main-cntr{
  height:90vh; 
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 2rem;
}
.media{height:200px; width: 300px;}
.MuiCardMedia-media{height: auto !important;}
.MuiCardContent-root{font-size: 1rem;}
.text-area textarea{line-height: 1.8; height: auto;}
.fileContainer{
  padding: 0px;
  margin: 0px; box-shadow: none;
}

.fileContainer p{display: none;}
.router-cntr{margin-top: 0.5rem;}
.action-btn-blk {
  margin: .65rem 0rem;;
  font-size: 0.85rem;
  text-align: center;
  border: 2px solid #d3d3d3;
  padding: 0.5rem 1rem;
  border-radius: 45px;
  justify-content: center;
  display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
  display: flex;
  align-items: center;
  /* font-weight: 700; */
}
.routing-instruction{margin-top: 4rem;}