/* @import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap'); */
*{padding: 0; margin: 0; box-sizing: border-box}
body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  font-family: "Roboto",-apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #486AAE; */
}
img{max-width: 100%;}
a{text-decoration: none !important; color: inherit !important;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.section-header{padding: 1rem 0rem; font-size: 1rem; text-align: center;}
.default-icon{width: 30px; height: 30px; border-radius: 50%; background-color: #FFBC36;}
.header-color{background-color: #486AA3;}
.inverted {	filter: invert(100%); }
.v-center{align-items: center;}